import { lazy, ReactNode, Suspense, useState } from "react";
import {Button, IconButton} from "@mui/material";
import classNames from "classnames";
import {
  ContactGuard,
  LoadingIndicator,
  Logo,
  PortfolioGuard,
} from "components";
import { CloseButton, SideBar } from "components/DNB";
import {
  ContactSelect,
  SearchContacts,
} from "components/DNB/ContactSelector/ContactSelect";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { AiFillHome as HomeIcon } from "react-icons/ai";
import { FaBook as HoldingsIcon, FaShoppingCart } from "react-icons/fa";

import {
  FaArrowRightArrowLeft as TransactionIcon,
  FaList as PortfolioIcon, FaPrint,
} from "react-icons/fa6";

import { HiOutlineMenu } from "react-icons/hi";
import { HiDocument } from "react-icons/hi2";
import { MdOutlineSavings } from "react-icons/md";
import { RiCoinsFill as CostsIcon } from "react-icons/ri";
import {
  Navigate,
  NavLink,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import {
  canSaveMode,
  canTradeMode,
  useCanSave,
  useCanTrade,
} from "services/permissions/trade";
import { config } from "../../config";
import {
  SelectedContact,
  useGetContractIdData,
} from "../../providers/ContractIdProvider";
import { useKeycloak } from "../../providers/KeycloakProvider";
import tailwindTheme from "../../tailwindTheme";

const Overview = lazy(() =>
  import("./overview").then((module) => ({ default: module.OverviewPage }))
);
const Portfolio = lazy(() =>
  import("./portfolio").then((module) => ({ default: module.PortfolioSelect }))
);
const PortfolioOverview = lazy(() =>
  import("./portfolio/overview").then((module) => ({
    default: module.OverviewPage,
  }))
);

const Holdings = lazy(() =>
  import("./holdings").then((module) => ({ default: module.HoldingsPage }))
);
const PortfolioHoldings = lazy(() =>
  import("./portfolio/holdings").then((module) => ({
    default: module.HoldingsPage,
  }))
);
const PortfolioHolding = lazy(() =>
  import("./portfolio/holdings/[holdingId]").then((module) => ({
    default: module.HoldingPage,
  }))
);

const Holding = lazy(() =>
  import("./holdings/[holdingId]").then((module) => ({
    default: module.HoldingPage,
  }))
);
const Transactions = lazy(() =>
  import("./transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const TransactionDetails = lazy(() =>
  import("./transactions/[transactionId]").then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);
const Documents = lazy(() =>
  import("./documents").then((module) => ({ default: module.DocumentsPage }))
);
const PortfolioDocuments = lazy(() =>
  import("./portfolio/documents").then((module) => ({
    default: module.DocumentsPage,
  }))
);
const Savings = lazy(() =>
  import("./savings").then((module) => ({ default: module.SavingsPage }))
);
const PortfolioSavings = lazy(() =>
  import("./portfolio/savings").then((module) => ({
    default: module.SavingsPage,
  }))
);

const Cost = lazy(() =>
  import("./costs").then((module) => ({
    default: module.CostPage,
  }))
);

const Orders = lazy(() =>
  import("./orders").then((module) => ({ default: module.OrdersPage }))
);

const OrderDetails = lazy(() =>
  import("./orders/[orderId]").then((module) => ({
    default: module.OrderDetailsPage,
  }))
);

const Contact = lazy(() =>
  import("./contact").then((module) => ({ default: module.ContactPage }))
);
const Trading = lazy(() =>
  import("./trading").then((module) => ({ default: module.TradingPage }))
);
const PortfolioTrading = lazy(() =>
  import("./portfolio/trading").then((module) => ({
    default: module.TradingPage,
  }))
);

const SHOW_COSTS_PAGE = config.featureFlags.costsPage.showCostsPage;

export const UserWithLinkedContactRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Navigate to="overview" replace />} />
      <Route path="" element={<MainLayout />}>
        <Route path="" element={<NavBar />}>
          <Route
            path="overview"
            element={
              <PortfolioGuard>
                <Overview />
              </PortfolioGuard>
            }
          />
          <Route
            path="portfolio"
            element={
              <PortfolioGuard>
                <Portfolio />
              </PortfolioGuard>
            }
          />
          <Route
            path="transactions"
            element={
              <PortfolioGuard>
                <Transactions />
              </PortfolioGuard>
            }
          />
          <Route
            path="savings"
            element={
              <PortfolioGuard>
                <Savings />
              </PortfolioGuard>
            }
          />
          {SHOW_COSTS_PAGE && (
            <Route
              path="costs"
              element={
                <PortfolioGuard>
                  <Cost />
                </PortfolioGuard>
              }
            />
          )}
          <Route
            path="holdings"
            element={
              <PortfolioGuard>
                <Holdings />
              </PortfolioGuard>
            }
          />
          <Route
            path="trading"
            element={
              <PortfolioGuard>
                <Trading />
              </PortfolioGuard>
            }
          />
          <Route
            path="trading/:securityId"
            element={
              <PortfolioGuard>
                <Trading />
              </PortfolioGuard>
            }
          />
          <Route
            path="documents"
            element={
              <PortfolioGuard>
                <Documents />
              </PortfolioGuard>
            }
          />
          <Route path="*" element={<h1>Not Found</h1>} />
          <Route path="transactions/*" element={<DetailsLayout />}>
            <Route path=":transactionId" element={<TransactionDetails />} />
          </Route>
          <Route path="holdings/*" element={<DetailsLayout />}>
            <Route path=":holdingId" element={<Holding />} />
          </Route>
        </Route>

        <Route path="portfolio/:portfolioId" element={<NavBar />}>
          <Route path="" element={<Navigate to="overview" replace />} />
          <Route path="overview" element={<PortfolioOverview />} />
          <Route path="holdings" element={<PortfolioHoldings />} />
          <Route path="documents" element={<PortfolioDocuments />} />
          <Route path="savings" element={<PortfolioSavings />} />
          <Route
            path="savings/add/:securityId"
            element={<PortfolioSavings />}
          />
          <Route path="trading" element={<PortfolioTrading />} />
          <Route path="trading/:securityId" element={<PortfolioTrading />} />
          <Route path="holdings/*" element={<DetailsLayout />}>
            <Route path=":holdingId" element={<PortfolioHolding />} />
          </Route>
          <Route path="*" element={<h1>Pf View - Not found</h1>} />
        </Route>
      </Route>
    </Routes>
  );
};

const MainLayout = () => {
  return (
    <ContactGuard>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </ContactGuard>
  );
};

const DetailsLayout = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full">
        <div onClick={() => navigate(-1)}>Back</div>
      </div>
      <Outlet />
    </>
  );
};

const NavBar = () => {
  const isDesktop = useMatchesBreakpoint("lg");
  const { t } = useModifiedTranslation();
  const canTrade = useCanTrade(canTradeMode.ANY);
  const canSave = useCanSave(canSaveMode.ANY);
  const _NavElements = [
    {
      title: t("navTab.tabs.overview"),
      to: "/overview",
      icon: <HomeIcon />,
    },
    {
      title: t("navTab.tabs.portfolios"),
      to: "/portfolio",
      icon: <PortfolioIcon />,
    },
    {
      title: t("navTab.tabs.transactions"),
      to: "/transactions",
      icon: <TransactionIcon />,
    },
    {
      title: t("navTab.tabs.holdings"),
      to: "/holdings",
      icon: <HoldingsIcon />,
    },
    {
      title: "Kostnader",
      to: "/costs",
      icon: <CostsIcon />,
      hidden: true,
      blocked: !SHOW_COSTS_PAGE,
    },
    {
      title: t("navTab.tabs.trading"),
      to: "/trading",
      icon: <FaShoppingCart />,
      blocked: !canTrade,
    },
    {
      title: t("navTab.tabs.savings"),
      to: "/savings",
      icon: <MdOutlineSavings />,
      blocked: !canSave,
    },
    {
      title: t("navTab.tabs.documents"),
      to: "/documents",
      icon: <HiDocument />,
      hidden: true,
    },
  ];

  const NavElements = _NavElements.filter((x) => !x.blocked);

  return (
    <>
      {isDesktop ? (
        <header className="flex fixed top-0 bottom-auto left-0 justify-between items-center px-4 w-full text-navtext bg-navbg border-b h-[var(--nav-bar)] z-[200] border-b-[#f1f1f1]">
          <Logo />
          <div className="flex absolute justify-center items-center w-full h-full">
            <div className="flex gap-8">
              <ul className="list-none">
                {NavElements.map((x, i) => {
                  return (
                    <NavElement
                      key={i}
                      title={x.title}
                      to={x.to}
                      icon={x.icon}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex gap-2">
            <ContactSelect />
            <IconButton onClick={() => window.print()}><FaPrint className="fill-buttonColor" /></IconButton>
          </div>
        </header>
      ) : (
        <div className="fixed bottom-0 left-0 w-full text-navtext bg-navbg h-[var(--nav-bar)] z-[200] border-b-[#f1f1f1]">
          <MobileNavBar elements={NavElements} />
        </div>
      )}
      <div
        className={classNames("relative py-4 mx-auto cont", {
          "mt-0 mb-[var(--nav-bar)]": !isDesktop,
          "mb-0 mt-[var(--nav-bar)]": isDesktop,
        })}
      >
        <Suspense fallback={<LoadingIndicator center />}>
          <Outlet />
        </Suspense>
      </div>
    </>
  );
};

interface MobileNavBarProps {
  elements: NavElementProps[];
}

const MobileNavBar = ({ elements }: MobileNavBarProps) => {
  const [expand, setExpand] = useState(false);
  const { t } = useModifiedTranslation();
  return (
    <div className="flex justify-between items-center w-full h-full">
      {elements
        .filter((x) => !x.hidden)
        .map((x, i) => {
          return (
            <NavLink
              title={x.title.toLowerCase()}
              to={x.to}
              className={({ isActive }) =>
                classNames(
                  "group flex-1 flex flex-col items-center justify-center text-xxs md:text-sm lg:text-base h-full text-center cursor-pointer  hover:text-prime border-t-2",
                  { "text-prime font-bold border-t-prime": isActive }
                )
              }
              key={i}
            >
              <div className="text-base group-hover:text-lg">{x.icon}</div>
              {x.title}
            </NavLink>
          );
        })}
      <div
        onClick={() => setExpand((prev) => !prev)}
        className={classNames(
          "group flex-1 flex flex-col items-center justify-center text-xxs md:text-sm lg:text-base h-full text-center cursor-pointer  hover:text-prime border-t-2"
        )}
      >
        <div className="text-base group-hover:text-lg">
          <HiOutlineMenu />
        </div>
        {t("navTab.tabs.menu")}
      </div>
      {expand && (
        <SideBarElement elements={elements} close={() => setExpand(false)} />
      )}
    </div>
  );
};

interface SideBarElementProps extends MobileNavBarProps {
  close: () => void;
}

const SideBarElement = ({ elements, close }: SideBarElementProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContact, setSelectedContactId, setSelectedContact } =
    useGetContractIdData();
  const { linkedContact } = useKeycloak();

  const setContact = (contact: SelectedContact) => {
    setSelectedContact(contact);
    setSelectedContactId(contact.id);
  };

  const logOut = () => keycloakService.onAuthLogout();
  return (
    <SideBar close={close}>
      <div className="box-border flex flex-col gap-4 p-4 h-[calc(100%-2rem)]">
        <div className="flex overflow-y-hidden flex-col flex-grow gap-4 justify-start h-full">
          <CloseButton onClick={close} />
          {/*<ContactSelect />*/}
          <SearchContacts
            onClose={() => console.log()}
            onSelect={(contact) => setContact(contact)}
            selectedContact={selectedContact}
            linkedContact={linkedContact}
            isMobile
          />
          <div className="text-xl font-bold">Sider</div>
          {elements
            .filter((item) => item.hidden === true)
            .map((x, i) => {
              return (
                <NavLink
                  key={i}
                  title={x.title.toLowerCase()}
                  onClick={close}
                  to={x.to}
                  className={({ isActive }) =>
                    classNames("text-xl hover:text-prime p-2", {
                      "text-prime font-medium border-t-prime bg-gray-100 rounded-md":
                        isActive,
                    })
                  }
                >
                  <div className="flex gap-2 justify-start items-center">
                    <div className="w-12 text-xl group-hover:text-lg">
                      {x.icon}
                    </div>
                    {x.title}
                  </div>
                </NavLink>
              );
            })}
        </div>
        <Button variant="contained" className="flex-none" onClick={logOut}>
          {t("component.contactSelect.logOut")}
        </Button>
      </div>
    </SideBar>
  );
};

interface NavElementProps {
  title: string;
  to: string;
  icon: ReactNode;
  hidden?: boolean;
  blocked?: boolean;
}

const NavElement = ({ title, to, icon }: NavElementProps) => {
  const isMd = useMatchesBreakpoint("md");
  return (
    <li className="inline-block">
      <NavLink
        title={title.toLowerCase()}
        to={to}
        className={({ isActive }) =>
          `h-full w-full md:w-auto md:h-auto flex justify-center md:px-2 md:py-1 border-b-[3px] ${
            isActive
              ? "font-bold fill-prime md:border-b-buttonColor"
              : "md:border-b-transparent"
          }`
        }
      >
        {title}
      </NavLink>
    </li>
  );
};
